$(document).ready(function(e) {
	/* h_menu_mob */
	$('.m_ctrl').on('click', function() {
		$('.h_menu_mob').slideToggle(300);
	});

	/*Слайдер*/
	$('.slider').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 5000,
		// arrows: false,
		dots: true,
		rows: 0
	});

	/*Карусель*/
	$('.carousel').slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		rows: 0,
		// autoplay: true,
		// autoplaySpeed: 5000,
		// arrows: false,
		responsive: [
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});

	/*/!*fancybox*!/
	$("[data-fancybox]").fancybox({
		buttons : [
			'close'
		]
	});*/

	/*Форма*/
	$('input, select').styler({
		selectPlaceholder: '',
		selectVisibleOptions: 5
	});
	$('.switch input').styler('destroy');

	/*Вкладки*/
	$('.tabs_wr').tabs();

	/*Scroll Pane*/
	$(function() {
		$('.scroll-pane').jScrollPane({
			autoReinitialise: true,
			verticalGutter: 10,
			verticalDragMinHeight: 100,
			verticalDragMaxHeight: 435,
			horizontalDragMinWidth: 100,
			horizontalDragMaxWidth: 435
		});
	});
});